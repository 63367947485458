import React from 'react';

class ImageCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = { spans: 0, imgHeight: 0 };

    this.imageRef = React.createRef();
  }

  componentDidMount() {
    this.imageRef.current.addEventListener('load', this.setSpans);
  }

  setSpans = () => {
    const height = this.imageRef.current.height;
    const spans = Math.ceil(height / 10);
    this.setState({ spans });
    this.setState({ imgHeight: this.imageRef.current.height });
    console.log(this.state.imgHeight);
  };

  render() {
    const { description, urls } = this.props.image;
    return (
      <div>
        <img
          className="imgA"
          ref={this.imageRef}
          alt={description}
          src={urls.regular}
        />
      </div>
    );
  }
}

export default ImageCard;
